// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import './registerServiceWorker'

import router from './router'
import './output.css'
// 全量引入 bk-magic-vue
import bkMagic from 'bk-magic-vue'
// 全量引入 bk-magic-vue 样式
import 'bk-magic-vue/dist/bk-magic-vue.min.css'
import store from './vuex'
import axios from 'axios'
// 引用API文件
import api from './api/index'
// filter统一引入
// import './fiter/index.js'
import cwMessage from './common/message'
// vuex
import '@/vuex/index' // 全局
import {hasPermission} from './promission.js' // 路由后台获取
import * as Echarts from 'echarts'
import message from "@/components/index"

Vue.use(message.register);
Vue.use(bkMagic)
Vue.use(axios)
Vue.use(Echarts)
Vue.prototype.$echarts = Echarts
Vue.prototype.$cwMessage = cwMessage
// 将API方法绑定到全局
Vue.prototype.$http = axios
Vue.prototype.$api = api
const headTheme = 'light' // 选择 light 或 blue
Vue.prototype.headTheme = headTheme
// Vue.prototype.$lodash = lodash
Vue.prototype.hasPerm = hasPermission
Vue.config.productionTip = false
// Vue.prototype.cloneDeep = function(data) {
//     return lodash.cloneDeep(data)
// }
Vue.prototype.setCookie = function(name, value, day) {
  if (day !== 0) {
    const curDate = new Date()
    const curTamp = curDate.getTime()
    const curWeeHours = new Date(curDate.toLocaleDateString()).getTime() - 1
    const passedTamp = curTamp - curWeeHours
    const leftTamp = 7 * 24 * 60 * 60 * 1000 - passedTamp
    const leftTime = new Date()
    leftTime.setTime(leftTamp + curTamp)
    document.cookie = name + '=' + escape(value) + ';expires=' + leftTime.toGMTString()
  } else {
    document.cookie = name + '=' + escape(value)
  }
}
Vue.prototype.getCookie = function(name) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  if (arr) {
    return unescape(arr[2])
  } else {
    return null
  }
}
Vue.prototype.formatDate = function (dateString) {
    const formattedDate = dateString.replace(/T/, ' ').replace(/\.000Z/, '');
    return formattedDate
}
/* eslint-disable no-new */
const vue = new Vue({
  el: '#app',
  router,
  store,
  components: {App},
  data() {
    return {
      website: '我是全局变量'
    }
  },
  template: '<App/>'
})
export default vue
