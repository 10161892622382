<template>
    <div class="overflow-y-auto h-screen-header" ref="albumview">
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <div class="flex justify-between w-full">
                <div>
                    <a v-for="{ value, text } in options" :key="value"
                        :class="{ 'tab': true, 'tab-active': sortValue === value }">
                        <router-link :to="{ 'path': '/albums/' + value }">
                            {{ text }}
                        </router-link>
                    </a>
                </div>
                <div class="flex justify-center items-center">
                    <a :class="{ 'tab': true, 'tab-active': showMode === 'grid' }" @click="showMode = 'grid'">
                        <svg class="icon w-5 h-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M170.666667 85.333333a85.333333 85.333333 0 0 0-85.333334 85.333334v213.333333a85.333333 85.333333 0 0 0 85.333334 85.333333h213.333333a85.333333 85.333333 0 0 0 85.333333-85.333333V170.666667a85.333333 85.333333 0 0 0-85.333333-85.333334H170.666667zM170.666667 554.666667a85.333333 85.333333 0 0 0-85.333334 85.333333v213.333333a85.333333 85.333333 0 0 0 85.333334 85.333334h213.333333a85.333333 85.333333 0 0 0 85.333333-85.333334v-213.333333a85.333333 85.333333 0 0 0-85.333333-85.333333H170.666667zM640 85.333333a85.333333 85.333333 0 0 0-85.333333 85.333334v213.333333a85.333333 85.333333 0 0 0 85.333333 85.333333h213.333333a85.333333 85.333333 0 0 0 85.333334-85.333333V170.666667a85.333333 85.333333 0 0 0-85.333334-85.333334h-213.333333zM640 554.666667a85.333333 85.333333 0 0 0-85.333333 85.333333v213.333333a85.333333 85.333333 0 0 0 85.333333 85.333334h213.333333a85.333333 85.333333 0 0 0 85.333334-85.333334v-213.333333a85.333333 85.333333 0 0 0-85.333334-85.333333h-213.333333z"></path></svg>
                    </a>
                    <a :class="{ 'tab': true, 'tab-active': showMode === 'list' }" @click="showMode = 'list'">
                        <svg class="icon w-5 h-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M134.976 853.312H89.6c-26.56 0-46.912-20.928-46.912-48.256 0-27.392 20.352-48.32 46.912-48.32h45.376c26.624 0 46.912 20.928 46.912 48.32 0 27.328-20.288 48.256-46.912 48.256zM134.976 560.32H89.6C63.04 560.32 42.688 539.392 42.688 512s20.352-48.32 46.912-48.32h45.376c26.624 0 46.912 20.928 46.912 48.32s-20.288 48.32-46.912 48.32zM134.976 267.264H89.6c-26.56 0-46.912-20.928-46.912-48.32 0-27.328 20.352-48.256 46.912-48.256h45.376c26.624 0 46.912 20.928 46.912 48.256 0 27.392-20.288 48.32-46.912 48.32zM311.744 853.312c-26.56 0-46.912-20.928-46.912-48.256 0-27.392 20.352-48.32 46.912-48.32h622.72c26.56 0 46.848 20.928 46.848 48.32 0 27.328-20.288 48.256-46.912 48.256H311.744c1.6 0 1.6 0 0 0zM311.744 560.32c-26.56 0-46.912-20.928-46.912-48.32s20.352-48.32 46.912-48.32h622.72c26.56 0 46.848 20.928 46.848 48.32s-20.288 48.32-46.912 48.32H311.744c1.6 0 1.6 0 0 0zM311.744 267.264c-26.56 0-46.912-20.928-46.912-48.32 0-27.328 20.352-48.256 46.912-48.256h622.72c26.56 0 46.848 20.928 46.848 48.256 0 27.392-20.288 48.32-46.912 48.32H311.744c1.6 0 1.6 0 0 0z"></path></svg>
                    </a>
                </div>
            </div>
        </div>
        <AlbumList :items="albums" v-if="showMode === 'grid'" :small="false" />
        <AlbumItems :items="albums" v-if="showMode === 'list'" />
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
        <button
            v-if="curScrollTop > 700"
            @click="scrollToTop"
            class="fixed bottom-8 right-8 bg-neutral-content hover:bg-accent hover: text-accent-content text-neutral font-bold py-2 px-4 rounded-full shadow-xl transition-colors duration-300"
        >
            <svg t="1730534469934" class="icon h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2399" width="200" height="200"><path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3c-3.8 5.3-0.1 12.7 6.5 12.7h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z" p-id="2400"></path></svg>
        </button>
    </div>
</template>
<script>
    import AlbumList from './AlbumList'
    import AlbumItems from './AlbumItems'
    import {mapGetters} from "vuex";
    export default {
        name: 'albums',
        components: {
            AlbumList, AlbumItems
        },
        props: {},
        data() {
            return {
                albums: [],
                loading: false,
                offset: 0,
                hasMore: true,
                showMode: 'grid',
                curScrollTop: 0,
                watchSearchTerm: null,
                searchTimeout: null
            }
        },
        computed: {
            ...mapGetters(['getSearchTerm']),
            options() {
                return [
                    {text: '最近添加', value: 'recently-added'},
                    {text: '最近播放', value: 'recently-played'},
                    {text: '最多播放', value: 'most-played'},
                    {text: 'A-Z排序', value: 'a-z'},
                    {text: '歌手排序', value: 'artist-sort'},
                    {text: '缺失封面', value: 'less_cover'}
                ]
            },
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            // sortValue: {
            //     handler() {
            //         console.log('watch me')
            //         // this.albums = []
            //         // this.offset = 0
            //         // this.hasMore = true
            //         // this.loadMore()
            //     }
            // },
            // getSearchTerm: {
            //     handler() {
            //         this.offset = 0
            //         this.hasMore = true
            //         this.loadSearch()
            //     }
            // }
        },
        mounted() {
        },
        beforeDestroy(){
        },
        destroyed() {
        },
        created() {
            console.log('created')
            this.loadMore()
            // this.watchSearchTerm = this.$watch('getSearchTerm', this.loadSearchTimeout);
        },

        //当组件第一次被创建时，会先触发created,后触发activated
        //当组件被激活时，只会触发activated，不触发created
        activated() {
            console.log('activated')
            this.$refs.albumview.addEventListener('scroll', this.listenBottomOut)
            window.albumview = this.$refs.albumview
            window.albumview.scrollTop = this.curScrollTop || 0
            this.startWatching()
        },
        deactivated(){
            // 离开页面取消监听
            window.albumview.removeEventListener('scroll', this.listenBottomOut, false)
            this.stopWatching()
        },
        methods: {
            startWatching() {
                if (!this.watchSearchTerm) {
                    console.log('watchSearchTerm')
                    this.watchSearchTerm = this.$watch('getSearchTerm', this.loadSearchTimeout);
                }
            },
            stopWatching() {
                if (this.watchSearchTerm) {
                    this.watchSearchTerm()
                    this.watchSearchTerm = null
                }
            },
            loadMore() {
                this.loading = true
                return this.$api.subsonic.getAlbums(this.sortValue, 50, this.offset).then(albums => {
                    this.albums.push(...albums)
                    this.offset += albums.length
                    this.hasMore = albums.length > 0
                    this.loading = false
                }).catch((err)=> {
                    if (err.message === 'Wrong username or password.'){
                        this.$myMsg.notify({
                            title: '查询失败！',
                            content: '请在后台管理创建一个subsonic密码，刷新页面试试吧',
                            type: 'error',
                            time: 5000,
                            url: '/admin/user/userprofile/'
                        })
                    } else {
                        this.$myMsg.notify({
                            title: '创建失败！',
                            content: err.messagee ? err.message : '创建失败',
                            type: 'error',
                            time: 5000,
                        })
                    }
                })
            },
            loadSearchTimeout() {
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(() => {
                    this.searchTimeout = null
                    this.loadSearch()
                }, 300)
            },
            loadSearch() {
                if (this.getSearchTerm === '') {
                    this.offset = 0
                    this.albums = []
                    this.hasMore = true
                    this.loadMore()
                    return
                }
                this.loading = true
                this.offset = 0
                this.hasMore = true
                return this.$api.subsonic.searchAlbums('search',this.getSearchTerm, 30, this.offset).then(albums => {
                    this.albums= albums
                    this.offset += albums.length
                    this.hasMore = albums.length > 0
                    this.loading = false
                }).catch((err)=> {
                    if (err.message === 'Wrong username or password.'){
                        this.$myMsg.notify({
                            title: '查询失败！',
                            content: '请在后台管理创建一个subsonic密码，刷新页面试试吧',
                            type: 'error',
                            time: 5000,
                            url: '/admin/user/userprofile/'
                        })
                    } else {
                        this.$myMsg.notify({
                            title: '创建失败！',
                            content: err.messagee ? err.message : '创建失败',
                            type: 'error',
                            time: 5000,
                        })
                    }
                })
            },
            listenBottomOut() {
                if (this.hasMore && !this.loading) {
                    const scrollTop = window.albumview.scrollTop
                    const scrollHeight = window.albumview.scrollHeight
                    const clientHeight = window.albumview.clientHeight
                    this.curScrollTop = scrollTop
                    if (scrollTop + clientHeight >= scrollHeight - 50) {
                        this.loadMore()
                    }
                }
            },
            scrollToTop() {
                window.albumview.scrollTop = 0
            }
        }
    }
</script>
