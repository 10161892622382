<template>
    <div class="relative form-control w-full" ref="selectContainer">
        <label class="input input-bordered input-sm h-auto flex items-center gap-2 py-1">
            <div class="grow flex flex-wrap gap-1 max-h-[120px] overflow-y-auto">
                <div v-for="(selected, index) in selectedLabels"
                     :key="index"
                     class="badge badge-ghost whitespace-nowrap my-0.5">
                    {{ selected }}
                    <span @click.stop="removeSelection(selected)" class="cursor-pointer text-gray-400 ml-1">×</span>
                </div>
                <input
                    type="text"
                    v-model="searchText"
                    class="grow w-0 outline-none my-0.5"
                    :placeholder="selectedValues.length ? '' : placeholder"
                    @click="toggleDropdown"
                    @keyup.enter="handleEnter"
                />
            </div>
            <svg t="1727684299243" v-show="selectedValues.length" @click.prevent.stop="clearInput" class="fill-gray-300 h-4 w-4 cursor-pointer mt-1 flex-shrink-0" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1612" width="200" height="200"><path d="M512 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667S747.648 85.333333 512 85.333333zM671.061333 662.848c-19.242667 19.221333-50.410667 19.221333-69.632 0l-81.216-81.216-81.216 81.216c-19.242667 19.221333-50.410667 19.221333-69.632 0-19.221333-19.242667-19.221333-50.410667 0-69.632L450.581333 512l-81.237333-81.216c-19.221333-19.242667-19.221333-50.410667 0-69.632 19.242667-19.221333 50.410667-19.221333 69.632 0l81.216 81.216 81.216-81.216c19.242667-19.221333 50.410667-19.242667 69.632 0 19.221333 19.242667 19.221333 50.410667 0 69.632L589.824 512l81.237333 81.216C690.282667 612.458667 690.304 643.626667 671.061333 662.848z"></path></svg>
        </label>
        <div class="mt-2">
            <div v-if="searchText[searchText.length - 1] === '$' && isVar">
                <div class="absolute top-full left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-box w-full max-h-[240px] overflow-y-auto">
                    <div
                        v-for="(suggestion, index) in suggestions"
                        :key="index"
                        class="menu-item p-2 cursor-pointer hover:bg-neutral hover:text-white"
                        @click="selectSuggestion(suggestion)"
                    >
                        {{ suggestion }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="showDropdown && optionsShow.length > 0"
                     class="absolute top-full left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-lg w-full border border-solid max-h-[280px] overflow-y-auto">
                    <div
                        :class="['menu-item', isAllSelected ? 'bg-base-200' : '', 'p-2', 'cursor-pointer', 'hover:bg-base-200', 'hover:rounded-lg']"
                        @click="toggleSelectAll"
                    >
                        <div class="flex justify-between items-center">
                            <div class="truncate mr-2">全选</div>
                            <div v-if="isAllSelected" class="flex-shrink-0">✓</div>
                        </div>
                    </div>
                    <div
                        v-for="(item, index) in optionsShow"
                        :key="'op'+index"
                        :class="['menu-item', value.indexOf(item.id) !== -1 ? 'bg-base-200' : '', 'p-2', 'cursor-pointer', 'hover:bg-base-200', 'hover:rounded-lg']"
                        @click="selectOption(item)"
                    >
                        <div class="flex justify-between items-center">
                            <div class="truncate mr-2">{{ item.name }}</div>
                            <div v-if="isSelected(item.id)" class="flex-shrink-0">✓</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: '请输入'
        },
        isVar: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDropdown: false,
            suggestions: ['${title}', '${artist}', '${album}', '${albumartist}', '${discnumber}', '${tracknumber}',
                '${filename}', '${file_song_name}', '${parent_path}', '${parent_parent_path}', '${lyrics}',
                '${comment}', '${year}', '${genre}', '${composer}', '${lyricist}', '${null}'],
            isSelecting: false,
            searchText: '',
            selectedValues: []
        };
    },
    computed: {
        selectedLabels() {
            return this.selectedValues.map(id =>
                this.options.find(opt => opt.id === id)?.name || id
            );
        },
        optionsShow() {
            return this.options.filter(opt =>
                !this.searchText || opt.name.toLowerCase().includes(this.searchText.toLowerCase())
            );
        },
        isAllSelected() {
            return this.optionsShow.length > 0 && this.value.length === this.optionsShow.length;
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.selectedValues = [...newValue];
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
        clearTimeout(this.blurTimeout);
    },
    methods: {
        handleClickOutside(event) {
            const container = this.$refs.selectContainer;
            if (container && !container.contains(event.target)) {
                this.showDropdown = false;
            }
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        selectOption(item) {
            const index = this.selectedValues.indexOf(item.id);
            if (index === -1) {
                this.selectedValues.push(item.id);
            } else {
                this.selectedValues.splice(index, 1);
            }
            this.$emit('input', this.selectedValues);
            this.$emit('change', this.selectedValues);
            this.isSelecting = true;
            this.searchText = '';
        },
        isSelected(id) {
            return this.selectedValues.includes(id);
        },
        removeSelection(label) {
            const option = this.options.find(opt => opt.name === label);
            if (option) {
                const index = this.selectedValues.indexOf(option.id);
                if (index !== -1) {
                    this.selectedValues.splice(index, 1);
                    this.$emit('input', this.selectedValues);
                    this.$emit('change', this.selectedValues);
                }
            }
        },
        selectSuggestion(value) {
            this.searchText = this.searchText.substring(0, this.searchText.length - 1) + value;
            this.$emit('input', this.searchText);
            this.showDropdown = false;
        },
        handleEnter() {
            this.$emit('enter', this.selectedValues);
        },
        clearInput() {
            this.showDropdown = false;
            this.searchText = '';
            this.selectedValues = [];
            this.$emit('input', []);
        },
        toggleSelectAll() {
            if (this.isAllSelected) {
                // 如果已经全选，则清空选择
                this.$emit('input', []);
                this.$emit('change', []);
            } else {
                // 如果未全选，则选择所有选项
                const allIds = this.optionsShow.map(item => item.id);
                this.$emit('input', allIds);
                this.$emit('change', allIds);
            }
        },
    }
};
</script>

<style scoped>
.select-box {
    position: relative;
    display: inline-block;
}

/* 美化滚动条样式 */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

.dropdown {
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.dropdown.active {
    display: block;
}

.dropdown div {
    padding: 5px;
}

.dropdown div:hover {
    background-color: #f8f8f8;
}
</style>
