import {
    clientName,
    getCoverArtUrl,
    normalizeAlbum,
    normalizeArtist,
    normalizePlaylist,
    normalizeTrack
} from './serializers'
import {auth} from './auth'

function toQueryString(params) {
    const list = Object.entries(params)
        .map(([key, value]) => Array.isArray(value) ? value.map((x) => [key, x]) : [[key, value]])
        .flat()
    return new URLSearchParams(list).toString()
}

const fetch = (path, params) => {
    const url = `${auth.server}${path}?${toQueryString({
        ...params,
        u: auth.username,
        s: auth.salt,
        t: auth.hash,
        c: clientName,
        f: 'json',
        v: '1.16.0'
    })}`
    return window
        .fetch(url, {
            method: 'GET',
            headers: {Accept: 'application/json'}
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            }
            const message = `Request failed with status ${response.status}`
            // Handle non-standard Navidrome response
            if (response.status === 501) {
                return Promise.reject(new Error(message))
            }
            return Promise.reject(new Error(message))
        })
        .then(response => {
            const subsonicResponse = response['subsonic-response']
            if (subsonicResponse.status === 'ok') {
                return subsonicResponse
            }
            const error = subsonicResponse.error
            const message = error && error.message ? error.message : subsonicResponse.status
            throw new Error(message)
        })
}

export default {
    async getAlbums(sort, size, offset = 0) {
        const type = {
            'a-z': 'alphabeticalByName',
            'artist-sort': 'alphabeticalByArtist',
            'recently-added': 'newest',
            'recently-played': 'recent',
            'most-played': 'frequent',
            'random': 'random',
            'less_cover': 'less_cover',
            'search': 'search',
        }[sort]
        const params = {type, offset, size}
        const response = await fetch('/rest/getAlbumList2/', params)
        const albumList = response.albumList2
        const albums = albumList && albumList.album ? albumList.album : []
        return albums.map(normalizeAlbum)
    },
    async searchAlbums(sort,searchTerm, size, offset = 0) {
        const type = {
            'a-z': 'alphabeticalByName',
            'recently-added': 'newest',
            'recently-played': 'recent',
            'most-played': 'frequent',
            'random': 'random',
            'less_cover': 'less_cover',
            'search': 'search',
        }[sort]
        const params = {type, search:searchTerm, offset, size}
        const response = await fetch('/rest/getAlbumList2/', params)
        const albumList = response.albumList2
        const albums = albumList && albumList.album ? albumList.album : []
        return albums.map(normalizeAlbum)
    },
    async getAlbumDetails(id) {
        const params = { id }
        const data = await fetch('/rest/getAlbum/', params)
        return normalizeAlbum(data.album)
    },
    async getArtists(type, size, offset = 0) {
        const response = await fetch('/rest/getArtists/', { type, size, offset })
        const artistIndex = response.artists && response.artists.index ? response.artists.index : []
        return (artistIndex)
            .flatMap((index) => index.artist)
            .map(normalizeArtist, this)
    },
    async searchArtists(type, search) {
        const response = await fetch('/rest/getArtists/', { type, search })
        const artistIndex = response.artists && response.artists.index ? response.artists.index : []
        return (artistIndex)
            .flatMap((index) => index.artist)
            .map(normalizeArtist, this)
    },
    async getArtistDetails(id) {
        const info2Promise = fetch('/rest/getArtistInfo2/', { id }).then(r => r.artistInfo2)
        const artist = await fetch('/rest/getArtist/', { id }).then(r => r.artist)
        const topSongs = await fetch('/rest/getTopSongs/', { artist: artist.name }).then(r => {
            return r.topSongs && r.topSongs.song ? r.topSongs.song : []
        })
        const info2 = await info2Promise
        return normalizeArtist({ ...artist, ...info2, topSongs })
    },
    async getTracksByArtist(id) {
        const artist = await fetch('/rest/getArtist', { id })
            .then(r => r.artist)
        console.log(233, artist)
        const albumIds = (artist.album || []).map(x => x.id)
        const tracksList = []
        for (const id of albumIds) {
            const { tracks } = await this.getAlbumDetails(id)
            if (tracks && tracks.length > 0) {
                tracksList.push(...tracks)
            }
        }
        return tracksList
    },
    async getTracksByGenre(id, size, offset = 0) {
        const params = {
            genre: id,
            count: size,
            offset
        }
        const response = await fetch('/rest/getSongsByGenre/', params)
        const song = response.songsByGenre && response.songsByGenre.song ? response.songsByGenre.song : []
        return (song).map(normalizeTrack, this)
    },
    async searchTracksByGenre(id, search='', size, offset = 0) {
        const params = {
            genre: id,
            count: size,
            offset,
            search
        }
        const response = await fetch('/rest/getSongsByGenre/', params)
        const song = response.songsByGenre && response.songsByGenre.song ? response.songsByGenre.song : []
        return (song).map(normalizeTrack, this)
    },
    async getTracksByArtist2(id, size, offset = 0) {
        const params = {
            artist_id: id,
            count: size,
            offset
        }
        const response = await fetch('/rest/getSongsByGenre/', params)
        const song = response.songsByGenre && response.songsByGenre.song ? response.songsByGenre.song : []
        return (song).map(normalizeTrack, this)
    },
    async getGenres(type) {
        const response = await fetch('/rest/getGenres/', { type })
        return (response.genres.genre || [])
            .map((item) => ({
                id: item.value,
                name: item.value,
                albumCount: item.albumCount,
                trackCount: item.songCount,
                image: getCoverArtUrl(item)
            }))
    },
    async getAlbumsByGenre(id, size, offset = 0) {
        const params = {
            type: 'byGenre',
            genre: id,
            size,
            offset
        }
        const response = await fetch('/rest/getAlbumList2/', params)
        const album = response.albumList2 && response.albumList2.album ? response.albumList2.album : []
        return (album).map(normalizeAlbum, this)
    },
    async getAlbumsByArtist(id, size, offset = 0) {
        const params = {
            type: 'byArtist',
            artist_id: id,
            size,
            offset
        }
        const response = await fetch('/rest/getAlbumList2/', params)
        const album = response.albumList2 && response.albumList2.album ? response.albumList2.album : []
        return (album).map(normalizeAlbum, this)
    },
    async getFavourites(type, size, offset = 0) {
        const params = {
            type,
            size,
            offset
        }
        const response = await fetch('/rest/getStarred2/', params)
        const albums = response.starred2 && response.starred2.album ? response.starred2.album : []
        const artists = response.starred2 && response.starred2.artist ? response.starred2.artist : []
        const songs = response.starred2 && response.starred2.song ? response.starred2.song : []
        return {
            albums: (albums).map(normalizeAlbum, this),
            artists: (artists).map(normalizeArtist, this),
            tracks: (songs).map(normalizeTrack, this)
        }
    },
    async getPlaylists(type) {
        const response = await fetch('/rest/getPlaylists/', { type })
        const playlist = response.playlists && response.playlists.playlist ? response.playlists.playlist : []
        return (playlist).map(normalizePlaylist, this)
    },
    async getPlaylist(id) {
        if (id === 'random') {
            return {
                id,
                name: 'Random',
                tracks: await this.getRandomSongs()
            }
        }
        const response = await fetch('/rest/getPlaylist/', { id })
        return {
            ...normalizePlaylist(response.playlist),
            tracks: (response.playlist.entry || []).map(normalizeTrack, this)
        }
    },
    async getRandomSongs() {
        const params = {
            size: 200
        }
        const response = await fetch('/rest/getRandomSongs/', params)
        const song = response.randomSongs && response.randomSongs.song ? response.randomSongs.song : []
        return (song).map(normalizeTrack, this)
    },
    async getSong(id) {
        const response = await fetch('/rest/getSong/', { id })
        const song = response.song ? response.song : {}
        return normalizeTrack(song)
    },
    async getLyrics(id) {
        const response = await fetch('/rest/getLyrics/', { id })
        return response.lyrics ? response.lyrics : {}
    },
    async search(query, artistCount = 3, artistOffset = 0, albumCount = 3, albumOffset = 0, songCount = 3, songOffset = 0) {
        const params = {
            query, artistCount, artistOffset, albumCount, albumOffset, songCount, songOffset
        }
        const data = await fetch('/rest/search3/', params)
        return {
            tracks: (data.searchResult3.song || []).map(normalizeTrack, this),
            albums: (data.searchResult3.album || []).map(normalizeAlbum, this),
            artists: (data.searchResult3.artist || []).map(normalizeArtist, this)
        }
    },
    async addFavourite(id, type) {
        const params = {}
        if (type === 'track') {
            params.id = id
        } else if (type === 'album') {
            params.albumId = id
        } else if (type === 'artist') {
            params.artistId = id
        }
        await fetch('/rest/star/', params)
    },
    async removeFavourite(id, type) {
        const params = {}
        if (type === 'track') {
            params.id = id
        } else if (type === 'album') {
            params.albumId = id
        } else if (type === 'artist') {
            params.artistId = id
        }
        await fetch('/rest/unstar/', params)
    },
    async createPlaylist(name, tracks) {
        const params = {
            name,
            songId: tracks
        }
        return fetch('/rest/createPlaylist/', params)
    },
    async createPlaylistAi(name, conditions, limit, orderBy, isDynamic) {
        const params = {
            name,
            conditions: conditions,
            limit: limit,
            'order_by': orderBy,
            'is_dynamic': isDynamic
        }
        return fetch('/rest/createPlaylist/', params)
    },
    async addToPlaylist(playlistId, tracks) {
        const params = {
            playlistId,
            songIdToAdd: tracks
        }
        await fetch('/rest/updatePlaylist/', params)
    },
    async removeFromPlaylist(playlistId, index) {
        const params = {
            playlistId,
            songIndexToRemove: index
        }
        await fetch('/rest/updatePlaylist/', params)
    },
    async deletePlaylist(id) {
        await fetch('/rest/deletePlaylist/', { id })
    },
    async scrobble(id) {
        return fetch('/rest/scrobble/', { id, submission: true })
    },
    async getDiscover() {
        const data = await fetch('/rest/getDiscover/', {})
        return {
            discover: data.discover.map(item => {
                item.image = getCoverArtUrl(item)
                return item
            })
        }
    },
}
