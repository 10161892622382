<template>
    <div class="hello">
        <single-select :options="options" v-model="value" is-var>
        </single-select>
        {{value2}}
        <div class="w-80">
            <multi-select :options="options" v-model="value2"></multi-select>
        </div>
        <button class="btn btn-success" @click="handleAlert('success')">Success</button>
        <button class="btn btn-warning" @click="handleAlert('warning')">Warning</button>
        <button class="btn btn-error" @click="handleAlert('error')">Error</button>
    </div>
</template>

<script>
import SingleSelect from "@/components/iview/SingleSelect";
import MultiSelect from "@/components/iview/MultiSelect";

export default {
    name: 'HelloWorld',
    components: {SingleSelect, MultiSelect},
    props: {
        msg: String
    },
    data() {
        return {
            value: '',
            value2: [ "1", "2" ],
            options: [
                {'id': '1', 'name': 'LLM'},
                {'id': '2', 'name': 'LLM2'},
                {'id': '3', 'name': 'LLM3'},
                {'id': '4', 'name': 'LLM4'},
                {'id': '5', 'name': 'LLM5'},
                {'id': '6', 'name': 'LLM6'},
                {'id': '7', 'name': 'LLM7xxxxxxxxxxxx'},
            ]
        }
    },
    methods: {
        handleAlert(type) {
            this.$myMsg.notify({
                title: '创建成功',
                content: "啦啦啦",
                type: type,
                time: 2000,
                url: '/#/albums/recently-added/'
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
