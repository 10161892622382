<template>
    <div class="relative">
        <div class="flex">
            <Sidebar class="!hidden md:!block" />
            <div class="w-full p-2">
                <keep-alive include="albums,artists,tracks,genres,genre,artist-tracks" max="18">
                    <router-view :key="$route.path"></router-view>
                </keep-alive>
            </div>
        </div>
        <div class="absolute bg-base-300 rounded-xl shadow-xl visible bottom-6 left-1/4 w-1/2 z-[19]">
            <Player />
        </div>
        <div class="drawer">
            <input id="showBar" type="checkbox" class="drawer-toggle" v-model="isShowSideBar" />
            <div class="drawer-content">
            </div>
            <div class="drawer-side z-10">
                <label for="showBar" aria-label="close sidebar" class="drawer-overlay"></label>
                <div class="p-4 min-h-full bg-base-100 text-base-content">
                    <Sidebar />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Sidebar from '@/components/library/Sidebar'
    import Player from '@/views/library/player/Player'
    export default {
        components: {
            Sidebar, Player
        },
        data() {
            return {
                isShowSideBar: false
            }
        },
        methods: {
            showSideBar() {
                this.isShowSideBar = !this.isShowSideBar
            }
        }
    }
</script>
